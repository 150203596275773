import { forwardRef, Inject, Injectable, Injector, NgZone } from '@angular/core';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

import {
  AngularFirestore,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseUrl = environment.apinestjs;
  userData: any; // Save logged in user data


  private logService: LogService;

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, 
    private messageService: NzMessageService,
    private http: HttpClient,
    private userService: UserService,
    public router: Router,
    private injector: Injector
  ) {
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
      } else {
        localStorage.setItem('user', 'null');
      }
    });

  }


  init(){
    this.logService = this.injector.get(LogService);
  }

  capchaValidate(captchaResponse) {
    return this.http.post(`${this.baseUrl}/users/captcha-validate`, { captchaResponse: captchaResponse})
  }

  customToken(token,typeDocument, document,idVehicle){
    const idloader = this.messageService.loading('Comprobando credenciales...').messageId;

    return this.afAuth.signInWithCustomToken(token)
    .then((result) => {
      this.afAuth.idTokenResult.subscribe((result) => {
        if(result){
          this.messageService.remove(idloader);
          localStorage.setItem('claims', JSON.stringify(result.claims));

          if(this.getCurrentUserTracking().length > 0){
            this.router.navigateByUrl('/tracking/' + String(typeDocument) + '-' + String(document) + '_' + String(idVehicle));
            return;
          }
          else{
            this.router.navigateByUrl('/tracking');
            return;
          }
          
        }
        else{
          throw new Error('Error 1');
          return;
        }
      })
    })
    .catch((error) => {
      this.messageService.remove(idloader);
      throw new Error('Error 2');
    });
  }

  //Validate Attempts
  checkAttempts(email: string, password: string) {

    let attempts = Number(localStorage.getItem('attempts')) ?? 0;

    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    const ttl = Number(localStorage.getItem('attempts_ttl')) ?? timestamp;
    
    if(timestamp >= ttl){
      if(attempts >= 3){
        localStorage.setItem('attempts', String(0));
        localStorage.removeItem('attempts_ttl');
      }
      this.SignIn(email, password);
    }
    else{

      let duration = ttl - timestamp;
      moment.locale('es');
      let readduration = moment.duration(duration,'ms').humanize();
      this.messageService.warning('Hemos notado tres intentos fallidos. Inténtalo nuevamente en '+ readduration +'.', {nzDuration: 6000});
    }
  }


  // Sign in with email/password
  SignIn(email: string, password: string) {

     //Log
     this.logService.logAction('Login', email ,  { })
     .then(() => console.log('Acción Login registrada'))
     .catch(error => console.error('Error:', error))


    const idloader = this.messageService.loading('Comprobando credenciales...').messageId;

    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.afAuth.idTokenResult.subscribe((result) => {
          if(result){
            this.messageService.remove(idloader);
            localStorage.setItem('claims', JSON.stringify(result.claims));

           
            if(['admin','manager'].includes(result.claims['role'])){
              if(this.userHasModule('deuda')){
                this.router.navigateByUrl('/search');
                return;
              }
              if(this.userHasModule('bonos')){
                this.router.navigateByUrl('/bonos');
                return;
              }
              if(this.userHasModule('tracking')){
                this.router.navigateByUrl('/tracking');
                return;
              }
              
            }
            else{
              if(this.getCurrentUserAccounts().length > 0){
                this.router.navigateByUrl('/search');
                return;
              }
              if(this.getCurrentUserBonos().length > 0){
                this.router.navigateByUrl('/bonos');
                return;
              }
              if(this.getCurrentUserTracking().length > 0){
                this.router.navigateByUrl('/tracking');
                return;
              }
              
            }
          }
        })
      })
      .catch((error) => {
        this.messageService.remove(idloader);
        
        let attempts = Number(localStorage.getItem('attempts')) ?? 0;
        localStorage.setItem('attempts', String(attempts + 1));

        if(attempts + 1 >= 3){
          const currentDate = new Date();
          const timestamp = currentDate.getTime();
          localStorage.setItem('attempts_ttl', String(timestamp + 180000));
          this.messageService.warning('Hemos notado tres intentos fallidos. Inténtalo nuevamente en 3 minutos.', {nzDuration: 6000});
        }else{
          this.messageService.warning('Verifique sus credenciales.');
        }
        
      });
    
  }



  // Sign up with email/password
  SignUp(email: string, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail();
      })
      .catch((error) => {
        window.alert(error.message);
      });
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.currentUser
      .then((u: any) => u.sendEmailVerification())
      .then(() => {
        this.router.navigate(['auth/verify-email']);
      });
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this.messageService.info('Se envio un email con las instrucciones para restabler su contraseña.');
      })
      .catch((error) => {
        this.messageService.warning('Verifique el correo electrónico.');
      });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user !== null;//&& user.emailVerified !== false ? true : false;
  }


  // Sign in with Microsoft
  MicrosoftAuth() {
    return this.AuthLogin(new auth.OAuthProvider('microsoft.com')
    .setCustomParameters({tenant: 'e326d54a-d79f-422f-8253-f4278c0b97b3'}))
    .then((res: any) => {
      //Do nothing
    });
  }


  // Auth logic to run auth providers
  AuthLogin(provider: any) {
    const idloader = this.messageService.loading('Comprobando credenciales...').messageId;

    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.afAuth.idTokenResult.subscribe((result) => {
          if(result){
            if(['admin','manager'].includes(result.claims['role'])){
                this.messageService.remove(idloader);
                localStorage.setItem('claims', JSON.stringify(result.claims));
                this.router.navigateByUrl('/search');
            }
            else{
              this.messageService.remove(idloader);
              this.messageService.warning('Usuario no encontrado. Solicite acceso al  administrador.');
            }
          }
        })
      })
      .catch((error) => {
        this.messageService.remove(idloader);
        if(error.code === 'auth/account-exists-with-different-credential'){
        
          let pendingCred = error.credential;
          let email = error.email;

          this.afAuth.fetchSignInMethodsForEmail(email).then((method) => {
            if (method[0] === 'password') {

              let password = prompt("Ingrese la contraseña asignada para el Portal de Clientes:");

              this.afAuth.signInWithEmailAndPassword(email, password).then((result) => {
                result.user.linkWithCredential(pendingCred).then((response) => {
                  console.log(response);
                  this.messageService.success('Cuenta corporativa enlazada correctamente. Por favor intente el acceso nuevamente.', { nzDuration: 5000});
                });
              })
              .catch((error) => {
                this.messageService.warning('Verifique sus credenciales.');
              });
 
            }
          });
        }
        else{
          this.messageService.remove(idloader);
          this.messageService.warning('Verifique sus credenciales.');
        }
        
        
      });
  }



  //Get Current User Role
  getCurrentUserRole(){
    const claims = JSON.parse(localStorage.getItem('claims'));
    if(claims){
      return claims.role ?? 'user';
    }
    else{
      return 'user';
    }
  }

  //Get Current User Name
  getCurrentUserName(){
    const claims = JSON.parse(localStorage.getItem('claims'));
    if(claims){
      return claims.name ?? 'Cliente';
    }
    else{
      return 'Cliente';
    }
  }

  getCurrentUserEmail(){
    const claims = JSON.parse(localStorage.getItem('claims'));
    if(claims){
      return claims.email ?? 'not-set';
    }
    else{
      return 'not-set';
    }
  }

   //Get Current User Accounts
   getCurrentUserAccounts(){
    const claims = JSON.parse(localStorage.getItem('claims'));
    if(claims){
      return claims.accounts ?? [];
    }
    else{
      return [];
    }
  }

  //Get Current User Bonos
  getCurrentUserBonos(){
    const claims = JSON.parse(localStorage.getItem('claims'));
    if(claims){
      return claims.bonos ?? [];
    }
    else{
      return [];
    }
  }

  //Get Current User Tracking
  getCurrentUserTracking(){
    const claims = JSON.parse(localStorage.getItem('claims'));
    if(claims){
      return claims.tracking ?? [];
    }
    else{
      return [];
    }
  }

  //User has module
  userHasModule(slug: string){
    const claims = JSON.parse(localStorage.getItem('claims'));
    if(claims){
      if(claims.role == 'admin' && ['users','clients','roles','contacts','logs'].includes(slug)) return true;
      if(claims.role == 'manager' || claims.role == 'admin') return claims.modules.includes(slug);
      if(claims.role == 'user'){
        switch (slug) {
          case 'deuda':
            return Boolean(this.getCurrentUserAccounts().length > 0);
            break;
          case 'bonos':
            return Boolean(this.getCurrentUserBonos().length > 0);
            break;
          case 'tracking':
            return Boolean(this.getCurrentUserTracking().length > 0);
          break;
          default:
            return false;
            break;
        }
      }
      if(claims.role == 'none') return false;
    }
    else{
      return false;
    }
  }

  userFirtsModulePath(){
    const claims = JSON.parse(localStorage.getItem('claims'));
    if(claims){
      if(claims.role == 'manager' || claims.role == 'admin') return claims.modules[0] ?? '/auth/sign-in';
      if(claims.role == 'user'){
        if(this.getCurrentUserAccounts().length > 0){
          return '/search';
        }
        else{
          if(this.getCurrentUserBonos().length > 0){
            return '/bonos'
          }
          else{
            if(this.getCurrentUserTracking().length > 0){
              return '/tracking'
            }
            else{
              this.messageService.warning('No pudimos acceder a tu información. Por favor comunícate con tu asesor.');
              return  '/auth/sign-in';
            }
          }
        }
      }
      if(claims.role == 'none'){
        return '/auth/sign-in';
      }
    }
    else{
      return '/auth/sign-in';
    }
  }



  // Sign out
  SignOut() {
    
    //const idloaderOut = this.messageService.loading('Cerrando sesión...', { nzDuration: 1500}).messageId;

    this.userService.logout(this.userData['uid']).subscribe({
      next: (data) => {
        console.log(data);
      },
      error: (err) => {
        console.log(err);
      }
    });

    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('claims');
      this.router.navigate(['auth/sign-in']);
    });
  }
}
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';

export interface LogEntry {
  timestamp: Date;       // Fecha y hora del log
  action: string;        // Acción realizada
  userEmail?: string;       // ID del usuario (opcional)
  userTarget?: string; // Usuario objetivo
  additionalData?: any;  // Datos adicionales
}

@Injectable({
  providedIn: 'root'
})
export class LogService {

  private collectionName = 'logs';

  constructor(private firestore: AngularFirestore, private authService: AuthService) { }

  // Método para registrar un log
  logAction(action: string, userTarget?: string, additionalData?: any): Promise<void> {

    let userEmail: string = '';
    if(['Login'].includes(action)){
      userEmail = 'No aplica';
    }
    else{
      userEmail = this.authService.userData.email;
    }

    const logEntry: LogEntry = {
      timestamp: new Date(),
      action,
      userEmail,
      userTarget,
      additionalData
    };

    // Agregar el log a Firestore
    return this.firestore.collection<LogEntry>(this.collectionName).add(logEntry)
      .then(() => console.log('Log registrado con éxito'))
      .catch(error => console.error('Error al registrar el log:', error));
  }
}

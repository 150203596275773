import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './layout/dashboard/dashboard.component';
import { GuestComponent } from './layout/guest/guest.component';



// route guard
import { AuthGuard } from './shared/guard/auth.guard';

const routes: Routes = [
  // Public routes
  {
    path: 'auth',
    component: GuestComponent,
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  // Private routes
  { path: '', pathMatch: 'full', redirectTo: '/search' },
  { 
    path: 'search',
    component: DashboardComponent, 
    loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),canActivate: [AuthGuard], data: {roles: ['admin','manager','user'], module: 'deuda'}
  },
  { path: 'logs',
    component: DashboardComponent,
    loadChildren: () => import('./modules/logs/logs.module').then(m => m.LogsModule),canActivate: [AuthGuard], data: {roles: ['admin'],module: 'logs'}
  },
  { path: 'roles',
    component: DashboardComponent,
    loadChildren: () => import('./modules/roles/roles.module').then(m => m.RolesModule),canActivate: [AuthGuard], data: {roles: ['admin'],module: 'roles'}
  },
  { path: 'users',
    component: DashboardComponent,
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),canActivate: [AuthGuard], data: {roles: ['admin'],module: 'users'}
  },
  { path: 'clients',
    component: DashboardComponent,
    loadChildren: () => import('./modules/clients/clients.module').then(m => m.ClientsModule),canActivate: [AuthGuard], data: {roles: ['admin'], module: 'clients'}
  },
  { path: 'contacts',
    component: DashboardComponent,
    loadChildren: () => import('./modules/contacts/contacts.module').then(m => m.ContactsModule),canActivate: [AuthGuard], data: {roles: ['admin'], module: 'contacts'}
  },
  { path: 'bonos',
    component: DashboardComponent,
    loadChildren: () => import('./modules/bonos/bonos.module').then(m => m.BonosModule),canActivate: [AuthGuard], data: {roles: ['admin','manager','user'], module: 'bonos'}
  },
  { path: 'tracking',
  component: DashboardComponent,
  loadChildren: () => import('./modules/tracking/tracking.module').then(m => m.TrackingModule),canActivate: [AuthGuard], data: {roles: ['admin','manager','user'], module: 'tracking'}
},
  {path: '**', redirectTo: '/search'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
